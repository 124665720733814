import { range } from './range';
/**
 * Random a non-cryptographic random string from characters a-zA-Z0-9.
 * @param length the length of the random string
 * @signature randomString(length)
 * @example
 *    randomString(5) // => aB92J
 * @category String
 */
export function randomString(length) {
    var characterSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var randomChar = function () {
        return characterSet[Math.floor(Math.random() * characterSet.length)];
    };
    return range(0, length).reduce(function (text) { return text + randomChar(); }, '');
}
